<template>
  <div class="product">
    <div class="list">
      <div
        class="item"
        v-for="(item, key) in list"
        :key="key"
        
      >
        <div class="back-img">
          <div class="title">{{ item.title }}</div>
          <img
            :src="require(`../../assets/imgs/${item.src}`)"
            :alt="item.title"
          />
        </div>
        <div class="text">
          <p v-for="(text, index) in item.message.split(',')" :key="index">
            {{ text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
export default {
  data() {
    return {
      list: [
        {
          title: "智慧政务",

          src: "illustration-2.png",
          message:
            "工商管理局信用评价平台软件,中科政务中心综合管理系统,中科公务车辆综合管理平台," +
            "中科档案管理系统,中科OA协同办公管理系统,中科财务管理系统 (及云平台),免接口数据采集系统",
          path: "government",
        },
        {
          title: "智慧国防",

          src: "illustration-3.png",
          message: "营区自动巡防系统,营区保密工作管理系统",
          path: "security",
        },
        {
          title: "智慧医疗",

          src: "illustration-4.png",
          message:
            "中科医院管理信息系统,中科电子病历管理系统,中科实验室信息管理系统",
          path: "hospital",
        },
        {
          title: "智慧交通",

          src: "illustration-5.png",
          message:
            "三维视频融合监控系统,虚拟现实高清视频融合监控系统," +
            "重点人员信息动态管控系统,中科党建平台,AI视频盯防系统",
          path: "safety",
        },
        {
          title: "智慧教育",

          src: "illustration-6.png",
          message:
            "标准化考点视频巡查系统,考生身份验证系统,作弊防控侦测防护系统,校园安全综合防控,智慧校园门禁管理平台",
          path: "education",
        },
        {
          title: "智慧园区",

          src: "illustration-7.png",
          message: "中科机房远程视频监控报警系统,中科PLM管理系统,中科智慧园区资产运营服务平台",
          path: "garden",
        },
      ],
    };
  },
  methods: {
    toViewMethod: ({ title }) => {
      const currentPath = router.currentRoute.path;
      const targetPath = "/case/detail";
      store.dispatch("changeDispatch", title);
      store.dispatch("changeDispatchTitle", null);
      if (currentPath !== targetPath) {
        router.push(targetPath);
      }
    },
  },
};
</script>

<style scoped lang="less">
.product {
  padding: 4rem 0;
  //box-sizing: border-box;
  .list {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 3rem;
    justify-content: center;
    .item {
      //height: 38rem;
      box-shadow: 0 0 3px 3px #c9cacd;
      //cursor: pointer;
    }
    .back-img {
      position: relative;
      img {
        width: 100%;
      }
      .title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5rem;
        color: white;
        font-size: 2rem;
      }
    }
    .text {
      padding: 1.5rem 2.5rem;
      box-sizing: border-box;
      p {
        margin-top: 0.6rem;
        font-size: 1.5rem;
      }
    }
  }
}
</style>

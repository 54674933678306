<template>
  <!--
   <el-container class="honor">
    <el-header class="honor-top">
      <Header />
    </el-header>
    <el-main class="honor-center">
     <Wall title="软件产品" english="Software Product"/>
     <s-product />
    </el-main>
    <el-footer class="honor-footer">
      <Footer />
    </el-footer>
  </el-container>
  -->
  <div>
	  <Header />
	  <Wall title="软件产品" english="Software Product"/>
	  <s-product />
	   <Footer />
  </div>
</template>

<script>
import sProduct from './productCenter/sProduct2.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Wall from "@/views/components/Wall.vue";
export default {
components:{
  sProduct,Header,Footer,Wall
}
}
</script>

<style scoped lang="less">
.honor-center{
  padding: 0 !important;
  display: block !important;
}
// .honor{
//   &-top{
//     position: fixed;
//     top:0;
//   }
// }
.honor{
  width: 100%;
  &-top{
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    background-color:#fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &-center{
    position: relative;
    top: 0rem;
    width: 100%;
    height: 100%;
    //padding-top: 60px;
    margin: 0;
    //margin-left: -1rem;
    box-sizing: border-box;
    background-color:#f4f4f4;
  }
   .honor-footer{
     top: 0rem;
     position: relative;
     width: 100%;
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
}
</style>
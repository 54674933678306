<template>
    <div class="head">
		<img src='../../assets/imgs/banner-3.png' class='bg-img'></img>
        <div class="head-title">
            <div>{{title}}</div>
            <div class="title-english">{{english}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        english: {
            type: String,
            required: true,
        },
    },
};
</script>
<style scoped lang="less">
.head {
    width: 100%;
    //height: 17rem;
    //background: url("../../assets/imgs/banner-3.png") center no-repeat;
    //background-size: cover;
    display: flex;
    align-items: center;
	margin-top:0rem;
	.bg-img{
		height:15rem;
		width:100%;
	}
    .head-title{
		position:absolute;
		top:11rem;
		//margin-top:3rem;
		//margin-bottom:6rem;
        color: white;
        font-size: 2.7rem;
        margin-left: 5rem;
        .title-english{
            font-size: 1.5rem;
        }
    }
}
</style>